import React from 'react';
import styles from './Place.module.css';
import place from "../images/ענבל רוזנפלד קליניקה מיקום.png"
const Location = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>מיקום האקדמיה</h2>
      <p className={styles.subtitle}>קריית ביאליק - צומת צבר</p>


      
    </div>
  );
};

export default Location;